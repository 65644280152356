import { Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { APP_VERSION } from "../utils/constants";

function PageWrapper(props = {}) {
  const { children } = props;
  const history = useHistory();

  return (
    <Box
      p={4}
      display="flex"
      height="100vh"
      pt="64px"
      flexDir="column"
      {...props}
    >
      {children}
      <Stack alignSelf="center" direction="row" spacing={2} mt={8}>
        <Button size="xs" onClick={() => history.push("/terms")} variant="link">
          Terms of service
        </Button>
        <Text>|</Text>
        <Button
          size="xs"
          onClick={() => history.push("/privacy-policy")}
          variant="link"
        >
          Privacy Policy
        </Button>
        <Text>|</Text>
        <a href="mailto:info@bargain-flex.com">
          <Button size="xs" variant="link">
            Support
          </Button>
        </a>
      </Stack>
      <Text textAlign="center" mt={2}>
        © 2024 Bargain Flex. All rights reserved. v{APP_VERSION}
      </Text>
    </Box>
  );
}

export default PageWrapper;
